.navbar-default {
    background-color: transparent;
    border: none;
    padding-top: 50px;
    z-index: 9;
    font-size: 21px;
    font-family: PFBagueSansPro;
}

.navbar-logo{
    float: right;
    height: 110px;
    cursor: pointer;
    transition: all 0.5s ease-in-out 0s;
}

.navbar-logo:hover{
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.navbar-default .navbar-toggle {
    border: none;
    float: left;
    padding-left: 0px;
    font-size: 21px;
    margin-top: 40px;
}

.white-navbar .navbar-default .navbar-toggle {
    color: #fff;
}

.black-navbar .navbar-default .navbar-toggle {
    color: $primary-color;
}

.navbar-default .navbar-toggle:hover {
    background-color: inherit;    
}

.navbar-default .navbar-collapse{
    padding-left: 0px;
}

.navbar-default .navbar-nav>li>a{
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 30px;
}

.white-nav .navbar-default .navbar-nav>li>a{
    color: $third-color;
}

.white-nav .navbar-default .navbar-nav>li>a:hover,
.white-nav .navbar-default .navbar-nav>li>a:focus,
.white-nav .navbar-default .navbar-nav>li>a:active{
    color: $third-color;
}

.black-nav .navbar-default .navbar-nav>li>a{
    color: $primary-color;
}

.black-nav .navbar-default .navbar-nav>li>a:hover{
    color: $primary-color;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover{
    background-color: inherit;
    color: #fff;
}

.white-nav .navbar-default .navbar-nav>li>.hvr-underline-from-center:before{
    height: 2px;
    background: #fff;
}

.white-nav option.hvr-underline-from-center:before{
    height: 2px;
    background: #fff;
}

.black-nav .navbar-default .navbar-nav>li>a.hvr-underline-from-center:before{
    height: 2px;
    background: $primary-color;
}

.black-nav option.hvr-underline-from-center:before{
    height: 2px;
    background: $primary-color;
}

.hvr-opt{
    display: block;
    padding-right: 10px;
}

.hvr-opt:before:focus,
.hvr-opt:before:active,
.hvr-opt:before:visited {
    background-color: transparent;
    box-shadow: none;
    outline: none;
}

.dropdown{
    font-family: PFBagueSansProLightItalic;    
}

.dropdown a{
    height: 50px;
}

.dropdown-menu {
    background-color: transparent; 
    border: none;
    box-shadow: none;
    color: $third-color;
}

.active{
    display: block;
}

#lang-select{
    background-color: transparent;
    border: none;
    font-size: 16px;
    overflow-y: hidden;
    font-family: PFBagueSansProLightItalic;
}

#lang-select:focus{
    outline: none;
}

#lang-select option{
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

#lang-select option:focus,
#lang-select option:active {
    background-color: transparent;
    outline: none;
    box-shadow: none!important;
}

option:focus ,option:visited,option:active,option:hover{
    outline: none;
    background-color: transparent; 
}

/* Mobile Menu */


#mobile-menu{
    position: fixed;
    top: 0;
    left: 0;
    width:  100%;
    height: 100%;
    z-index: 999;
    display: none;
    background-color: #fff;
    overflow-y: auto;
}

.navbar-default #mobile-menu .navbar-nav{
    position: absolute;
    width: 160px;
    top: 90px;
    left: 50%;
    margin-left: -80px;
}

.navbar-default #mobile-menu .navbar-nav>li>a{
    color: $primary-color;
    padding-right: 0px;
    width: auto;
}

#mobile-menu .hvr-underline-from-center:before{
    background-color: $primary-color;
    margin-right: 5%;
}

#mobile-menu .sm-hvr-underline:before{
    margin-right: 35%;
}

#mobile-menu .lang-btn{
    height: 50px;
    padding-top: 10px;
    padding-bottom: 0px;
}

#mobile-menu-btn:hover,#mobile-menu-btn:active,#mobile-menu-btn:focus{
    background: transparent;
}

#mobile-menu .hvr-opt{
    color: $primary-color;
}

.fb-mob{
    padding-top: 30px;
}

.info-mob{
    padding: 20px 0px 20px 10px;
    color: #333;
    font-size: 18px;
}

@media(max-width: 485px){
  .col-xxx-12{
    width: 100%;
  }
  
  .ampe-info-wrapper h2 {
    margin-left: 0px;
    }
}

/* Extra Small Devices, Phones */ 
@media (max-width : 991px) {
    .navbar-collapse {
        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        min-height: 100%;
        padding-right: 15px;
        padding-left: 15px;
        border: none;
        box-shadow: none;
        z-index: 999;
        overflow-y: auto;

    }
    
    .navbar-default .in .navbar-nav {
        position: absolute;
        width: 150px;
        top: 90px;
        left: 0;
        right: 0;
        margin: auto;
     }

    .navbar-default .in .navbar-nav li a{
        color: #333;
        padding: 5px 10px 5px 0px;
        font-size: 21px;
    }

    .navbar-default .in .navbar-nav li a:hover{
        color: #333;
    }
    
    .navbar-nav>li {
        clear: both;
    }
    .menu-close-btn{
        position: absolute;
        top: 40px;
        right: 40px;
        cursor: pointer;
    }
    
    .dropdown a{
        height: 30px;
    }
    
    .info-mob{
        padding-left: 0px;
        font-size: 16px;
    }
    
    .dropdown-menu{
        position: relative;
    }
    
    .hvr-underline-from-center:before{
        margin-right: 5%;
        height: 2px;
    }
    
}


@media(max-width: 767px){
    #mobile-menu-btn{
        margin-left: 20px;
    }
    
    .navbar-logo{
        margin-right: 20px;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px ) and ( max-width : 991px) {    
    .navbar-collapse.collapse {
        display: none !important;
    }
    .navbar-collapse.collapse.in {
        display: block !important;
    }
    .navbar-header .collapse, .navbar-toggle {
        display:block !important;
    }
    .navbar-header {
        float:none;
    }
    
    .navbar-nav>li>a {
        padding-top: 5px;
        padding-bottom: 10px; 
    }
    
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    
    .navbar-logo{
        height: 130px;
    }
    
    .left-nav{
        padding-top: 50px;
    }
    
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    
    .navbar-logo{

    }

}