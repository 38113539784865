@font-face{
    font-family: PFBagueSansPro;
    src: url(../fonts/PFBagueSansPro.otf);
}

@font-face{
    font-family: PFBagueSansProMedium;
    src: url(../fonts/PFBagueSansPro-Medium.otf);
}

@font-face{
    font-family: PFBagueSansProLight;
    src: url(../fonts/PFBagueSansPro-Light.otf);
}

@font-face{
    font-family: PFBagueSansProLightItalic;
    src: url(../fonts/PFBagueSansPro-LightItalic.otf);
}

body{
    margin: 0px;
    padding: 0px;
    font-family: PFBagueSansPro;
    color: #313130;
    overflow-x: hidden;
    font-size: 18px;
}

*::selection {
    background-color: rgb(49, 49, 48);
    color: #fff;
}
*::-moz-selection {
    background: rgb(49, 49, 48);
    color: #fff;
}
*::-webkit-selection {
    background: rgb(49, 49, 48);
    color: #fff;
}

.col-xxs-1,.col-xxs-2,.col-xxs-3,
.col-xxs-4,.col-xxs-5,.col-xxs-6,
.col-xxs-7,.col-xxs-8,.col-xxs-9,
.col-xxs-10,.col-xxs-11,.col-xxs-12{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.container{
    max-width: 1170px;
}

.auto-clear{
    clear: both;
}

.header-section{
    min-height: 100%;
    margin-bottom: 40px;
    padding-bottom: 40px;
}

.header-page{
    font-size: 36px;
}

.normal-text{
    margin-bottom: 10px;
}

.normal-medium-bold-text{
    margin-bottom: 10px;
    font-family: PFBagueSansProMedium;
}

.normal-itallic-text{
    margin-bottom: 10px;
    font-family: PFBagueSansProLightItalic;
}

.cst-hr{
    width: 135px;
    height: 2px;
    margin-top: 0px;
    margin-bottom: 35px;
}

.cst-top-margin{
    margin-top: 20px;
}

.left-hr{
    float: left;
}

.cst-hr-white{
    background-color: $third-color;
}

.cst-hr-black{
    background-color: $primary-color;
}

/* ----- Headers ----- */

.sct-main-header{
    margin-top: 80px;
    margin-bottom: 35px;
    font-size: 36px;
}

.sct-second-header{
    margin-top: 35px;
    margin-bottom: 35px;
    font-size: 36px;
}

.header-center{
    text-align: center;
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    
    .header-page{
        font-size: 44px;
    }
    
    .normal-text,.normal-medium-bold-text,.normal-itallic-text{
        font-size: 19px;
    }
        
    .cst-hr{
        width: 155px;
    }
    
    .sct-header{
        font-size: 44px;
    }
    
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    
    .header-section{
        height: 700px;
    }
    
    .header-page{
        font-size: 48px;
    }
    
    .normal-text,.normal-medium-bold-text,.normal-itallic-text{
        font-size: 20px;
    }
    
    .sct-header{
        font-size: 36px;
    }
    
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    
    .sct-main-header{
        font-size: 48px;
    }
}