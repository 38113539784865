#contact-header{
    background-color: #1d1d1b;
    color: $third-color;
}

#contact-header .normal-medium-bold-text span{
    font-family: PFBagueSansPro;
}

#map{
    min-height: 400px;
    width: 100%;
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    
    #map{
        min-height: 700px;
    }
    
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}