#vineyard-header{
    background: url(../images/ampelwnas-bg.jpg) no-repeat 100%;
    background-size: cover;
    height: 700px;
    color: #FFF;
}

ul.tab{
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-align: center;
    margin-bottom: 40px;
}

ul.tab li {
    display: inline;
}

ul.tab li a {
    display: inline-block;
    color: $primary-color;
    text-align: center;
    padding: 14px 1.8vw;
    font-size: 4.8vw;
    text-decoration: none;
    transition: 0.3s;
    cursor: pointer;
}

ul.tab li .dot{
    font-size: 22px;
}

ul.tab li a:hover{
    color: #313130;
}

ul.tab li a.active:hover{
    color: #a4123f;
}

ul.tab li a.active.hvr-underline-from-center:before{
    background: #a4123f;
}

ul.tab li a.hvr-underline-from-center:before{
    margin: 0px 16px;
    background: #313130;
}

.tabcontent{
    margin-top: 10px;
    display: none;
}

.tab-wrapper div.active{
    display: block;
}

.tabcontent h1{
    color: $third-color;
    position: absolute;
    top: 40%;
    left: 8.33333333%;
    font-size: 9.4vw;
    margin: 0px 0px;
    padding: 0px 0px;
}

.tabcontent .cst-hr{
    margin-top: 40px;
}

.ampe-info-wrapper{
    z-index: 99;
}

.ampe-info-inner{
    background-color: rgb(231, 231, 231);
    padding: 0px 10px 20px;
}

.ampe-info-wrapper .close-btn{
    position: absolute;
    top: 45px;
    right: 45px;
}

.ampe-info-wrapper h2{
    color: rgb(164, 18, 63);
    margin-top: 0px;
    padding-top: 140px;
    padding-left: 40px;
    z-index: 99;
    margin-bottom: 90px;
}

.img-custon-wrapper{
    float: left;
    width: 40px;
}

.img-custom-icon{
    display: block;
    margin: auto;
}

.header-custom-icon{
    float: left;
    margin: 0px 20px;
    font-size: 21px;
}

.ampe-info-item{
    padding: 50px 20px 80px 60px;
}

@media(min-width: 540px){
    .tabcontent h1{
        font-size: 48px;
    }
}


@media(min-width: 768px){
    
    ul.tab li a {
        padding: 14px 16px;
        font-size: 32px;
    }
    
    ul.tab li .dot{
        font-size: 32px;
    }
    
    .tabcontent h1{
        top: 45%;
        left: 8.33333333%;
        font-size: 48px;
    }
    
}

@media(min-width: 992px){
    
    .ampe-image-wrapper{
        padding-right: 0px;
    }
    
    .ampe-info-wrapper{
        padding-left: 0px;
    }
    
}

@media(min-width: 1200px){
  
    .ampe-info-inner{
        height: 705px;
    }
    
    .ampe-info-wrapper h2{
        padding-left: 0px;
        margin-left: -35px;
    }
}
