#winery-header{
    background: url(../images/winery-bg.jpg) no-repeat 100%;
    background-size: cover;
    color: #FFF;
    height: 700px;
}

.winery-images-wrapper{
    background-color: rgb(228, 228, 228); 
    margin-bottom: 40px;
    padding-bottom: 20px;
}

#base-img{
    cursor: pointer;
}

.tool-tip{
    padding: 15px 20px 10px;
    display: none;
    background-color: #fff;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
}

.oino-tap{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    z-index: 999;
}

.infobox-wrapper{
    position: relative;
    padding: 10px 20px;
    display: none;
    background-color: #fff;
    font-size: 14px;
    max-width: 100%;
}

.infobox-wrapper .header{
    color: #a4123f;
    font-size: 16px;
}

.infobox-wrapper .info{
    margin: 10px 0px;
}

.infobox-wrapper .back-link i{
    margin-right: 10px;
}

.infobox-wrapper .back-link{
    cursor: pointer;
}

.infobox-wrapper .back-link:hover{
    color: #a4123f;
}

@media(max-width: 445px){
    #winery-header{
        height: 850px;
    }
}

@media (min-width: 768px){
     .tool-tip{
         position: absolute;
         top: 20px;
         right: 50px;
         width: 200px;
     }
}

@media (min-width: 992px){
    
    .tool-tip{
         position: absolute;
         top: 20px;
         right: 100px;
     }
    
    .infobox-wrapper{
        position: absolute;
        padding: 10px 20px;
        display: none;
        background-color: #fff;
        font-size: 14px;
        max-width: 250px;
    }
    
    #upodoxi{
        top: 42%;
        left: 65%;
    }
    
    #piestirio{
        top: 2%;
        left: 54%;
    }
    
    #orimansi{
        top: 8%;
        left: 0%;
    }
    
    #oinotrapeza{
        top: 16%;
        left: 45%;
    }
    
    #xhmeio{
        top: 40%;
        left: 44%;
    }
    
    #ekthetirio{
        top: 15%;
        left: 61%;
    }
    
    #oinopoiio{
        top: 5%;
        left: 50%;
    }
    
    #oinopoieio-inout{
        top: 10%;
        left: 65%;
    }
    
    #parousiaseis{
        top: 5%;
        left: 38%;
    }
    
    #emfialwsi{
        top: 30%;
        left: 60%;  
    }
    
    
}
