/* Footer */

.fir-foot{
    margin-top: 100px;
    padding: 20px 15px 0px 15px;
}

.fir-foot img{
    float: right;
}

.fir-foot-hr{
    border-width: 2px;
    border-color: #333;
}

.sec-foot{
    padding: 25px 15px 50px 15px;
}

.sec-foot-serve{
    padding: 25px 15px 50px 15px;
}

.sec-foot-serve img{
    float: right;
}

.sec-foot-nav{
    padding: 60px 15px 50px 15px;
    font-size: 16px;
}

.footer-nav{
    float: right;
    list-style: none;
}

.footer-nav li{
    display: inline;
}

.footer-nav li a{
    color: #313130;
    text-decoration: none;
}

.footer-nav li .dot{
    padding: 0px 10px 0px 10px;
}

.footer-nav li a:hover,.footer-nav li a:active{
    color: #a4123f;
}

.tir-foot-copy{
    font-size: 14px;
    padding: 15px;
    margin-bottom: 60px;
}

.tir-foot-wearetwo{
    padding: 15px;
    margin-bottom: 60px;
}

.tir-foot-wearetwo a{
    color: #313130;
    float: right;
    font-size: 13px;
}

.tir-foot-wearetwo a:hover{
    text-decoration: none;
    color: #a4123f;
}

@media(min-width: 767px){
    
    .tir-foot-copy{
        font-size: 16px;
    }
    
    .tir-foot-wearetwo a{
        font-size: 14px;
    }
    
}