#home-page{
    color: #fff;
}

.intro-wrapper{
    position: fixed;
    top: 0px;
    z-index: 999;
    background-color: #fff;
    min-height: 100%;
    width: 100%;
}
    
.progress-bar{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 3px;
    background-color: #333333; 
    animation: intro-loader;
    animation-duration: 3s;
}

@keyframes intro-loader{
    from{
        width: 0%;
    }
    to{
        width: 100%;
    }
}

.intro-gif img{ 
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    margin-top: -100px;
    margin-left: -100px;
}

#timelapse{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover !important;
    background-position: center;
    z-index: 1;
}

.bgimg{
    position: fixed;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 1;
}

#home-page{
    background-color: inherit;
    z-index: 2;
}

.page-main-header{
    color: #FFF;
    line-height: 1;
    z-index: 2;
}

.page-main-header h1{
    margin: 0px 0px 0px 0px;
    font-size: 36px;
}

.page-main-header hr{
    margin: 25px 0px 25px 0px;
    width: 23%;
    float: left;
    border: 5px solid #FFF;
}
.page-main-header h2{
    margin: 40px 0px 0px 0px;
    clear: both
}

.page-vertical-header{
    z-index: 4;
}

.coordinates{
    height: auto;
}

.coordinates h3 a{
    color: #FFF;
    position: relative;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 60px;
    font-family: PFBagueSansProLight;
    text-decoration: none;
}
     
.coordinates h3 a:hover{
    text-decoration: none;
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
    
    .page-main-header{
        margin-top: 60px;
    }
    
    .page-main-header h1{
        font-size: 32px;
    }
    
    .page-main-header hr{
        width: 78px;
    }
    
    .page-main-header h2{
        font-size: 28px;
    }
    
    .coordinates h3{
        margin-top: 80px;
    }
    
    .coordinates h3 a{
        font-size: 22px;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    
    .page-main-header{
        margin-top: 60px;
    }
    
    .page-main-header h1{
        font-size: 46px;
    }
    
    .page-main-header hr{
        width: 110px;
    }
    
    .page-main-header h2{
        font-size: 40px;
    }

    .coordinates h3 a{
        font-size: 52px;
        -moz-transform: matrix( 0, -0.47891014010993, 0.47891014010993, 0, 0, 0);
        -webkit-transform: matrix( 0, -0.47891014010993, 0.47891014010993, 0, 0, 0);
        position: absolute;
        margin: 40% 0px 0px -12%;
        width: 750px;
    }

    
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    
    .page-main-header{
        margin-top: 60px;
    }
    
    .page-main-header h1{
        font-size: 52px;
    }
    
    .page-main-header hr{
        width: 130px;
    }
    
    .page-main-header h2{
        font-size: 48px;
    }
    
    .coordinates h3 a{
        font-size: 52px;
        -moz-transform: matrix( 0, -0.47891014010993, 0.47891014010993, 0, 0, 0);
        -webkit-transform: matrix( 0, -0.47891014010993, 0.47891014010993, 0, 0, 0);
        position: absolute;
        margin: 19% 0% 0px -9%;
        width: 900px;
    }
    
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    
    .page-main-header{
        margin-top: 60px;
    }
    
    .page-main-header h1{
        font-size: 80px;
    }
    
    .page-main-header h2{
        font-size: 50px;
    }
    
    .coordinates h3 a{
        font-size: 62px;
        -moz-transform: matrix( 0, -0.47891014010993, 0.47891014010993, 0, 0, 0);
        -webkit-transform: matrix( 0, -0.47891014010993, 0.47891014010993, 0, 0, 0);
        position: absolute;
        margin: 130px 0px 0px 6px;
        width: 1000px;
    }
}
               