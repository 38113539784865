#products-header #lang-select,#products-header #mobile-menu-btn{
    color: #333;
}
/* Slider */


.arrow{
    position: absolute;
    top: 50%;
    z-index: 99;
}

.arrow-left{
    left: 30px;
}

.arrow-right{
    right: 30px;
}

/* Products List Items */

.wines-row div{
    margin-bottom: 40px;
}

.hover-wines{
    max-width: 100%;
    transition: all 0.3s ease;
    display: block;
    margin: auto;
}

.hover-wines:hover{
    transform: scale(1.1);
}

.red-mockup{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 60px;
    border-radius: 5px;
    background-color: $secondary-color;
    margin-left: -100px;
    margin-top: -30px;
    color: #fff;
    z-index: 999;
    text-align: center;
    padding: 5px 10px 0px 10px;
}

.onhover-active{
    display: block;
}

.onhover-wines-info{
    font-size: 16px;
    padding-bottom: 20px;
}

.onhover-wines-info-inner{
    background-color: rgb(231, 231, 231);
    padding: 0px 10px 20px;
}

.onhover-wines-info .close-btn{
    position: absolute;
    top: 45px;
    right: 45px;
}

.onhover-wines-info h2{
    color: rgb(164, 18, 63);
    margin-top: 0px;
    padding-top: 40px;
    padding-left: 40px;
}

.info-item ul{
    list-style: none;
}

.info-item ul li .info-item-header,.info-item ul li .info-item-btn{
    margin: 40px 0px 40px 60px;
}

.info-item ul li img{
    clear: both;
    float: left;
}

.info-item ul li p{
    margin-left: 60px;
    margin-bottom: 40px;
}

.info-item-btn{
    border-radius: 5px;
    background-color: rgb(164, 18, 63);
    color: #fff;
    padding: 15px 20px 15px 20px;
    transition: 0.5;
}

.info-item-btn:hover{
    background-color: #850521;
    text-decoration: none;
}

// Red Warrior
.warrior_wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}

.warrior_left {
    width: 40%;
}

.warrior_left .hover-wines:hover {
    transform: none !important;
}

.warrior-right {
    width: 60%;
    background-color: #ff0000;
    padding: 20px;
    font-size: 20px;
    color: #231f20;
    font-weight: 700;
}

@media(min-width: 767px){
    
    .onhover-wines-info{
        height: 520px;
    }
    
    .onhover-wines-info .close-btn{
        position: absolute;
        top: 60px;
        right: 60px;
    }

    .onhover-wines-info h2{

    }
    
    .slider .img-adaptive{
        height: auto;
    }
    
    .info-item ul li p{
        font-size: 18px;
        margin-bottom: 30px;
    }
    
    .info-item ul li p:last-child{
        margin-bottom: 40px;
    }
    
    .info-item ul li .info-item-btn{
        margin: 60px 0px 40px 60px;
    }
    
}


@media(min-width: 992px){
    
    .onhover-wines-info{
        height: 560px;
        background-color: rgb(231, 231, 231);
    }
    
    .onhover-wines-info-inner{
        background: transparent;
    }
    
    .onhover-wines-info .close-btn{
        position: absolute;
        top: 45px;
        right: 45px;
    }

    .onhover-wines-info h2{
        padding-left: 25px;
        margin-left: -35px;
    }
    
    .info-item ul li p{
        font-size: 18px;
    }
    
    .info-item ul li p:last-child{
        margin-bottom: 25px;
    }
       
}

@media(min-width: 1200px){
    .onhover-wines-info{
        height: 680px;
    }
    
    .onhover-wines-info h2{
        padding-left: 25px;
        margin-left: -35px;
    }
    
    .info-item ul li p{
        font-size: 20px;
    }
    
    .info-item ul li p:last-child{
        margin-bottom: 40px;
    }
}

